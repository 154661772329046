<template>
  <div>
    <eden-page-header :title="'Feedback'" />
  </div>
</template>

<script>
export default {
  name: "DashboardFeedback",
  data() {
    return {};
  },
};
</script>
